/*  feat screen 00 start */
.custom .react-datepicker {
  width: 328px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

/* feat_screen_07-02-01_04 start */
@media only screen and (max-width: 768px) {
  .custom .react-datepicker {
    width: 100% !important;
  }
  .custom .react-datepicker__month {
    width: 100% !important;
  }
}
/* feat_screen_07-02-01_04 end */
.custom .react-datepicker__day-names,
.custom .react-datepicker__week {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 1px 0;
  gap: 1px;
  height: 3.3rem; 
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.custom .react-datepicker__week:nth-child(2n-1) span {
  background-color: #F0F6FF;
}


.custom .react-datepicker__day-name,
.custom .react-datepicker__day,
.custom .react-datepicker__time-name {
  color: #000;
  color: #fff;
  display: inline-block;
  min-width: 2rem;
  width: 100%;
  line-height: 3.3rem;
  text-align: center;
  margin: 0;
}

.custom .react-datepicker__header  {
  transform: translateY(-2px);
}

.custom .react-datepicker__day-name {
  background-color: #008599;
  font-size: 18px;
  font-weight: 700;
}

.custom .react-datepicker__day-name:first-child {
  color:#E50000;
  border-top-left-radius: 0.3rem;
}
.custom .react-datepicker__day-name:last-child {
  color: #07CF84;
  border-top-right-radius: 0.3rem;
}
/*  feat screen 00 end */

