body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* feat_common_responsive_scrollbar_start */
@media only screen and (min-width: 740px) {
  * ::-webkit-scrollbar {
    width: 13px;
    height: 13px;
  }
}
/* feat_common_responsive_scrollbar_end */

* ::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 7px;
}

/* feat_common_responsive_scrollbar_start */
@media only screen and (max-width: 768px) {
  *::-webkit-scrollbar:horizontal {
    height: 4px;
  }
  .react-datepicker-wrapper {
    width: 100% !important;
  }
  img {
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
  body {
    overscroll-behavior: none;
  }
}
/* feat_common_responsive_scrollbar_end */