.react-datepicker {
  width: 328px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  padding-bottom: 12px;
  margin-right: 8px;
}
.react-datepicker-popper {
  z-index: 10;
}

/* feat_screen_07-02-01_04 start */
@media only screen and (max-width: 768px) {
  .react-datepicker {
    width: 300px;
  }
  .react-datepicker__month {
    width: 300px !important;
  }
}
/* feat_screen_07-02-01_04 end */

.react-datepicker__month-container {
  float: left;
  width: 100%;
}
.react-datepicker__header {
  background-color: #fff;
  padding: 0px;
  position: relative;
  border: none;
  margin-bottom: 7px;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
  height: 64px;
}
.react-datepicker__day-names,
.react-datepicker__week {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 2px 12px;
  gap: 4px;
  height: 40px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  margin: 0;
}
.react-datepicker__day--disabled {
  color: #ccc;
}
.react-datepicker__month {
  margin: 0;
  text-align: center;
  width: 328px;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: transparent;
  color: #000000;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: #008599;
  border-radius: 50%;
  font-weight: normal;
  color: var(--negative-text);
}
.react-datepicker__day--selected {
  color: #f8feff;
}
.react-datepicker__day:hover {
  border-radius: 50%;
  background: #f0f0f0;
}
