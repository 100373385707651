.td4tnpo{color:var(--td4tnpo-0);font-family:'Meiryo';font-style:normal;-webkit-flex:none;-ms-flex:none;flex:none;-webkit-order:1;-ms-flex-order:1;order:1;-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;}
.b99fo13.td4tnpo{font-weight:400;font-size:24px;line-height:36px;}
.h1thvg0s.td4tnpo{font-weight:700;font-size:20px;line-height:30px;}
.h1hkgwg8.td4tnpo{font-weight:700;font-size:16px;line-height:24px;}
.h1e3g9sf.td4tnpo{font-weight:700;font-size:14px;line-height:21px;}
.b1h4m0p9.td4tnpo{font-weight:400;font-size:18px;line-height:27px;}
.b3u2rqw.td4tnpo{font-weight:700;font-size:18px;line-height:27px;}
.b1g9n3zj.td4tnpo{font-weight:400;font-size:16px;line-height:24px;}
.b1l47jxr.td4tnpo{font-weight:700;font-size:16px;line-height:24px;}
.biaiwva.td4tnpo{font-weight:400;font-size:14px;line-height:21px;}
.b1mfx5xn.td4tnpo{font-weight:700;font-size:14px;line-height:21px;}
.b1o9h4op.td4tnpo{font-weight:400;font-size:14px;line-height:21px;-webkit-text-decoration-line:underline;text-decoration-line:underline;}
.c8pz4zk.td4tnpo{font-weight:400;font-size:12px;line-height:18px;}
.cle3sp0.td4tnpo{font-weight:700;font-size:12px;line-height:18px;}
.c1gqhv7p.td4tnpo{font-weight:400;font-size:12px;line-height:18px;-webkit-text-decoration-line:underline;text-decoration-line:underline;}
.c1gth1j4.td4tnpo{font-weight:400;font-size:11px;line-height:18px;}
.c16dhdzc.td4tnpo{font-weight:700;font-size:10px;line-height:15px;}
.fj91ukm{position:absolute;width:100%;height:100%;}
.h1vkra3u{box-shadow:0px 2px 4px rgba(0,0,0,0.16);border-radius:8px 8px 0px 0px;}
.f1nouaaq{box-shadow:0px -2px 4px rgba(0,0,0,0.16);border-radius:0px 0px 8px 8px;}
.tyaak76{box-sizing:border-box;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:4px 8px;gap:8px;width:var(--tyaak76-0);height:var(--tyaak76-1);background:#ffffff;border:1px solid #dbdbdb;border-radius:2px;-webkit-flex:none;-ms-flex:none;flex:none;-webkit-order:1;-ms-flex-order:1;order:1;-webkit-align-self:stretch;-ms-flex-item-align:stretch;align-self:stretch;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;}
.t1nl545l{resize:none;box-sizing:border-box;width:752px;height:156px;background:#FFFFFF;border:1px solid #DBDBDB;border-radius:2px;-webkit-flex:none;-ms-flex:none;flex:none;-webkit-order:1;-ms-flex-order:1;order:1;-webkit-align-self:stretch;-ms-flex-item-align:stretch;align-self:stretch;-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;}
.b11fvxdb{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:0px 0px 0px 8px;gap:8px;width:var(--b11fvxdb-0);height:var(--b11fvxdb-1);-webkit-flex:none;-ms-flex:none;flex:none;-webkit-order:1;-ms-flex-order:1;order:1;-webkit-align-self:stretch;-ms-flex-item-align:stretch;align-self:stretch;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;}
.dvw6r5e{width:var(--dvw6r5e-0);height:1px;-webkit-flex:none;-ms-flex:none;flex:none;-webkit-order:var(--dvw6r5e-1);-ms-flex-order:var(--dvw6r5e-1);order:var(--dvw6r5e-1);-webkit-align-self:stretch;-ms-flex-item-align:stretch;align-self:stretch;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;position:static;left:0%;right:0%;top:0%;bottom:0%;background:#DBDBDB;}
