.sekd5nf{position:fixed;bottom:0;left:0;right:0;height:60px;background-color:#f8feff;box-shadow:#00000075 0px 4px 10px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-tap-highlight-color:transparent;-webkit-transition:linear 0.2s;transition:linear 0.2s;z-index:4;}
.sddtd0m{position:absolute;left:1px;top:-4px;}
.sph0lrq{position:absolute;left:5px;top:8px;}
.s18t2la5{position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;height:100%;margin:3px;padding:2px 0;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;cursor:pointer;width:100%;}
.s1obywtj{background-color:var(--s1obywtj-0);position:relative;width:33px;height:33px;border-radius:50%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.s1vym0s{position:absolute;background-color:#ffffff;width:140px;border-radius:8px;bottom:50px;box-shadow:#00000047 0px 4px 8px;z-index:9999;left:var(--s1vym0s-0);}
.s76kxm8{list-style:none;border-radius:8px 8px 0 0;}.s76kxm8 li:first-child{border-radius:8px 8px 0 0;}.s76kxm8 li:last-child{border-radius:0 0 8px 8px;}
.s6xihtx{position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:38px;padding-left:18px;-webkit-box-pack:start;-webkit-justify-content:flex-start;-ms-flex-pack:start;justify-content:flex-start;background-color:var(--s6xihtx-0);color:var(--s6xihtx-1);font-size:14px;}.s6xihtx::before{content:' ';position:absolute;width:0;height:0;bottom:-8px;left:var(--s6xihtx-2);margin-left:-10px;border-style:solid;border-width:var(--s6xihtx-3);border-color:var(--s6xihtx-4);}.s6xihtx:hover svg{fill:#ffffff !important;}.s6xihtx:hover svg path{fill:#ffffff !important;}
.s1blezp7{margin:0;}
.s6b7uu9{font-size:9px;margin:0;padding:2px 0;color:#008599;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
