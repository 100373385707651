.cjijp0i{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;padding:0px;gap:24px;position:absolute;width:328px;height:372.03px;left:50%;top:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);}
.f9ny056{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:8px;gap:4px;width:328-16px;height:86.03-16px;-webkit-flex:none;-ms-flex:none;flex:none;-webkit-order:0;-ms-flex-order:0;order:0;-webkit-align-self:stretch;-ms-flex-item-align:stretch;align-self:stretch;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;}
.f13l61cw{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;padding:0px;gap:4px;width:200px;height:70.03px;-webkit-flex:none;-ms-flex:none;flex:none;-webkit-order:0;-ms-flex-order:0;order:0;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;}
.b96onqu{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;padding:0px;gap:10px;width:var(--b96onqu-0);height:var(--b96onqu-1);-webkit-flex:none;-ms-flex:none;flex:none;-webkit-order:0;-ms-flex-order:0;order:0;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;}
.l1fao86i{box-sizing:border-box;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:0px;gap:24px;width:328px;height:262px;background:#FFFFFF;border-radius:6px;-webkit-flex:none;-ms-flex:none;flex:none;-webkit-order:1;-ms-flex-order:1;order:1;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;}
.alp1pub.b3u2rqw.td4tnpo{width:var(--alp1pub-0);height:var(--alp1pub-1);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;text-align:center;-webkit-flex:none;-ms-flex:none;flex:none;-webkit-order:1;-ms-flex-order:1;order:1;-webkit-align-self:stretch;-ms-flex-item-align:stretch;align-self:stretch;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.pxdl1u7.c1gth1j4.td4tnpo{width:var(--pxdl1u7-0);height:var(--pxdl1u7-1);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-text-decoration-line:underline;text-decoration-line:underline;-webkit-flex:none;-ms-flex:none;flex:none;-webkit-order:2;-ms-flex-order:2;order:2;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;}
.l1bbe86q{box-sizing:border-box;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:0px;gap:24px;position:absolute;width:var(--l1bbe86q-0);height:var(--l1bbe86q-1);left:calc(50% - 188px);top:93px;background:#FFFFFF;border-radius:6px;}
.l1yrqff8.b1l47jxr.td4tnpo{width:var(--l1yrqff8-0);height:var(--l1yrqff8-1);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:#008599;-webkit-flex:none;-ms-flex:none;flex:none;-webkit-order:0;-ms-flex-order:0;order:0;-webkit-align-self:stretch;-ms-flex-item-align:stretch;align-self:stretch;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;}
.hp2hdcy.dvw6r5e{background:#008599;}
.l1qa0qy8.biaiwva.td4tnpo{width:var(--l1qa0qy8-0);height:var(--l1qa0qy8-1);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex:none;-ms-flex:none;flex:none;-webkit-order:0;-ms-flex-order:0;order:0;-webkit-align-self:stretch;-ms-flex-item-align:stretch;align-self:stretch;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;}
.l440m49.c1gth1j4.td4tnpo{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-text-decoration-line:underline;text-decoration-line:underline;width:var(--l440m49-0);height:var(--l440m49-1);-webkit-flex:none;-ms-flex:none;flex:none;-webkit-order:3;-ms-flex-order:3;order:3;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;}
.f1daf0yo{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:var(--f1daf0yo-0);-webkit-box-align:var(--f1daf0yo-0);-ms-flex-align:var(--f1daf0yo-0);align-items:var(--f1daf0yo-0);padding:0px;gap:var(--f1daf0yo-1);width:var(--f1daf0yo-2);height:var(--f1daf0yo-3);-webkit-flex:none;-ms-flex:none;flex:none;-webkit-order:var(--f1daf0yo-4);-ms-flex-order:var(--f1daf0yo-4);order:var(--f1daf0yo-4);-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;}
.thq1r76.tyaak76{-webkit-order:0;-ms-flex-order:0;order:0;}
.p1ukjc3h.c8pz4zk.td4tnpo{width:var(--p1ukjc3h-0);height:var(--p1ukjc3h-1);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex:none;-ms-flex:none;flex:none;-webkit-order:1;-ms-flex-order:1;order:1;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;}
.f11fmngj{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:0px;gap:4px;position:absolute;width:164px;height:21px;left:calc(50% - 82px);top:40px;}
.l1ht640j.b1mfx5xn.td4tnpo{width:56px;height:21px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;text-align:center;-webkit-flex:none;-ms-flex:none;flex:none;-webkit-order:1;-ms-flex-order:1;order:1;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;}
